import styled from 'styled-components'

const WrapperStyled = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: ${({ maxWidth }) => maxWidth || '1280'}px;
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }
  @media screen and (min-width: 1366px) {
    padding: 0 5rem;
  }
`

function Wrapper({ children, maxWidth, ...otherProps }) {
  return (
    <WrapperStyled className="wrapper" maxWidth={maxWidth} {...otherProps}>
      {children}
    </WrapperStyled>
  )
}

export default Wrapper
