
/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import PropTypes from 'prop-types'

const PrimaryButton = styled.button`
  font-family: ${({ font }) => font || 'var(--primaryFont)'};
  line-height: ${({ lineHeight }) => lineHeight || '24px'};
  color: ${({ color }) => color};
  letter-spacing: ${({ spacing }) => spacing || '0.0125em'};
  background: ${({ background }) => background};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'initial')};
  border-radius: ${({ rounded, radius }) => ((radius > -1) ? `${radius}px` : rounded ? '22px' : '4px')};
  ${({ disabled }) => (disabled && `
    cursor: not-allowed;
    background: var(--primaryDisabled);
  `)}
  padding: ${({ padding }) => (padding ? `${padding}px` : '12px 32px')};
  font-weight: ${({ fontWeight }) => (fontWeight || '600')};
  ${({ fullWidth }) => (fullWidth
    ? `
      display: block;
      width: 100%;
    `
    : 'display: inline-block;'
  )}
  height: ${({ height }) => (height ? `${height}px` : '48px')};
  width: ${({ width }) => width};
  box-sizing: border-box;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
  border: none;
  appearance: none;
  text-decoration: none;
  outline: 0;
  transition: 200ms ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${({ activeColor, disabled }) => !disabled && activeColor};
    background: ${({ activeBackground, disabled }) => !disabled && activeBackground};
  }
  &:active {
    background: ${({ clickedBackground, disabled }) => !disabled && clickedBackground};
  }
  & > * {
    margin-left: .5em;
  }
  & > *:first-child {
    margin-left: 0;
  }
  ${({ large }) => large && 'font-size: 1.3em;'}
  ${({ small }) => small && (`
    font-size: 14px;
    line-height: 22px;
    height: 32px;
  `)}
  ${({ medium }) => medium && (`
    height: 40px;
  `)}
  ${({ responsive }) => responsive && (`
    height: 40px;
    @media(min-width: 768px){
      height: 48px;
    }
  `)}
  svg {
    margin-right: 8px;
  }
  @media(min-width: 768px){
    ${({ large }) => large && 'font-size: 2em;'}
    ${({ fitContent }) => fitContent && 'width: fit-content;'}
  }
`

function Button({
  radius,
  background,
  color,
  activeBackground,
  clickedBackground,
  activeColor,
  disabled,
  uppercase,
  rounded,
  fontWeight,
  lineHeight,
  fullWidth,
  large,
  height,
  medium,
  small,
  secondary,
  areaLabel,
  ...otherProps
}) {
  return (
    <PrimaryButton
      {...otherProps}
      color={color}
      activeColor={activeColor}
      disabled={disabled}
      background={background}
      activeBackground={activeBackground || 'var(--primaryHover)'}
      clickedBackground={clickedBackground || 'var(--primaryActive)'}
      uppercase={uppercase}
      rounded={rounded}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      fullWidth={fullWidth}
      large={large}
      medium={medium}
      small={small}
      radius={radius}
      height={height}
    />
  )
}

// const ButtonRef = forwardRef(({
//   radius,
//   background,
//   color,
//   activeBackground,
//   clickedBackground,
//   activeColor,
//   disabled,
//   uppercase,
//   rounded,
//   fontWeight,
//   lineHeight,
//   fullWidth,
//   large,
//   height,
//   medium,
//   small,
//   secondary,
//   areaLabel,
//   ...otherProps
// }, ref) => (
//   <PrimaryButton
//     ref={ref}
//     {...otherProps}
//     color={color}
//     activeColor={activeColor}
//     disabled={disabled}
//     background={background}
//     activeBackground={activeBackground || 'var(--primaryHover)'}
//     clickedBackground={clickedBackground || 'var(--primaryActive)'}
//     uppercase={uppercase}
//     rounded={rounded}
//     fontWeight={fontWeight}
//     lineHeight={lineHeight}
//     fullWidth={fullWidth}
//     large={large}
//     medium={medium}
//     small={small}
//     radius={radius}
//     height={height}
//   />
// ))

const SecondaryButton = styled(PrimaryButton)`
  background: var(--secondary);
  color: var(--white);
  transition: 200ms ease-in-out background;
  font-family: var(--primaryFont);
  border-radius: 12px;
  padding: 6px 1.5rem 10px;
  height: 40px;
  box-shadow: inset 0px -4px 0px #890C49;
  transition: .2s transform;
  ${({ disabled }) => (disabled ? `
    box-shadow: inset 0px -4px 0px #E6BEDE;
    background: #E6BEDE;
    cursor: not-allowed;
  ` : `
    &:hover {
      background: #FA057C;
    }
    &:active {
      background: #AA0351;
    }
  `)}
  &:active {
    box-shadow: none;
    transform: translateY(4px);
  }
`

const TertiaryButton = styled(PrimaryButton)`
  background-color: transparent;
  font-weight: ${({ fontWeight }) => (fontWeight || 500)};
`

const ButtonOutline = styled(PrimaryButton)`
  border: 1px solid var(--white);
  padding: 0;
  color: var(--white);
  background: var(--neutral110);
  padding-inline: 1.5rem;
  border-radius: 12px;
  padding-block-start: 6px;
  padding-block-end: 10px;
  box-shadow: inset 0px -3px 0px var(--white);
  line-height: 24px;
  height: 40px;
  &:active {
    box-shadow: none;
    transform: translateY(4px);
  }
  &:hover {
    background: var(--neutral90);
  }
  &:active {
    background: var(--neutral100);
  }
`

const SecondaryButtonOutline = styled(PrimaryButton)`
  border: 1px solid var(--secondary);
  color: var(--secondary);
  background: transparent;
  transition: 200ms ease-in-out opacity background;
  ${({ disabled }) => (disabled && 'opacity: 0.5;')};
  &:hover {
    ${({ disabled }) => (!disabled && 'background: #E6BEDE')};
  }
  &:active {
    ${({ disabled }) => (!disabled && 'background: #E177BE')};
  }
`

const NeutralButtonOutline = styled(PrimaryButton)`
  border: 1px solid var(--baseSecondary);
  color: var(--baseSecondary);
  background: var(--neutral110);
  &:hover {
    ${({ disabled }) => (!disabled && 'background: var(--neutral90)')};
  }
  &:active {
    ${({ disabled }) => (!disabled && 'background: var(--neutral100)')};
  }
  ${({ disabled }) => (disabled ? `
    cursor: not-allowed;
    opacity: 0.5;
  ` : '')};
  ${({ redesign }) => (redesign ? `
    font-family: var(--primaryFont);
    border-radius: 12px;
    padding: 6px 32px 10px;
    height: 40px;
    box-shadow: inset 0px -2px 0px var(--white);
  ` : '')};
`

const TransparentButton = styled.button`
  border: none;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  line-height: normal;
  letter-spacing: 0.0125em;
  align-items: center;
  cursor: pointer;
  display: ${({ display }) => display || 'inline-flex'};
  color: ${({ color }) => color || 'inherit'};
  margin: ${({ margin }) => margin || 0};
  font: ${({ font }) => `var(--${font})` || 'var(--buttonRegular)' || 'inherit'};
  font-weight: ${({ fontWeight }) => (fontWeight || '600')};
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}
  svg {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }
`

const OutlineButton = styled(PrimaryButton)`
  border: 1px solid var(--baseFullContrast);
  color: var(--baseFullContrast);
  background: var(--transparent);
  border-radius: 12px;
  padding: 6px 32px 10px;
  height: 40px;
  box-shadow: inset 0px -2px 0px var(--white);
  ${({ disabled }) => (!disabled ? `
    &:hover {
      background: var(--neutral90);
    }
    &:active {
      background: var(--neutral100);
    }
  ` : `
    cursor: not-allowed;
    opacity: 0.5;
  `)}
`

// const ButtonOutline = forwardRef(({
//   onClick, href, children, ...otherProps
// }, ref) => (
//   <ButtonOutline radius={4} href={href} onClick={onClick} ref={ref} {...otherProps}>
//     {children}
//   </ButtonOutline>
// ))

// const Button = forwardRef(({
//   onClick, href, children, ...otherProps
// }, ref) => (
//   <ButtonRef href={href} onClick={onClick} ref={ref} {...otherProps}>
//     {children}
//   </ButtonRef>
// ))

// const SecondaryButton = forwardRef(({
//   onClick, href, children, ...otherProps
// }, ref) => (
//   <SecondaryButton href={href} onClick={onClick} ref={ref} {...otherProps}>
//     {children}
//   </SecondaryButton>
// ))

// const NeutralButtonOutline = forwardRef(({
//   onClick, href, children, ...otherProps
// }, ref) => (
//   <NeutralButtonOutline href={href} onClick={onClick} ref={ref} {...otherProps}>
//     {children}
//   </NeutralButtonOutline>
// ))

const primaryDefaultProps = {
  color: 'var(--white)',
  disabled: false,
  uppercase: false,
  rounded: false,
  light: false,
  fullWidth: false,
  large: false,
  small: false,
  medium: false,
  secondary: false,
  height: '48',
  background: 'var(--primary)',
  radius: 4,
  font: 'var(--secondaryFont)',
}

Button.defaultProps = primaryDefaultProps
// ButtonRef.defaultProps = primaryDefaultProps

const primaryPropTypes = {
  /** Sets the color of the button text */
  color: PropTypes.string,
  /** Disables the button */
  disabled: PropTypes.bool,
  /** Makes the button letters uppercase */
  uppercase: PropTypes.bool,
  /** Increase the border-radius to 22px */
  rounded: PropTypes.bool,
  /** Set the font-weight to normal, by default is bold */
  light: PropTypes.bool,
  /** Makes the button width equals to 100% */
  fullWidth: PropTypes.bool,
  /** Increase font-size(2em) and padding */
  large: PropTypes.bool,
  /** Set font-size to 1em */
  small: PropTypes.bool,
  /** Set font-size to 1.5em */
  medium: PropTypes.bool,
  /** Set the background color */
  background: PropTypes.string,
  /** Sets secondary button style */
  secondary: PropTypes.bool,
  radius: PropTypes.number,
  height: PropTypes.string,
  font: PropTypes.string,
}

Button.propTypes = primaryPropTypes
// ButtonRef.propTypes = primaryPropTypes

export default Button

export {
  OutlineButton,

  // PrimaryBtn
  PrimaryButton,

  // Blue
  Button,
  // ButtonLink,
  ButtonOutline,
  // ButtonOutlineLink,

  // Pink
  SecondaryButton,
  // SecondaryButtonLink,
  SecondaryButtonOutline,

  // Neutral
  NeutralButtonOutline,
  // NeutralButtonOutline,

  TertiaryButton,
  TransparentButton,
}
