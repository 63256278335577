import React from 'react'
import styled from 'styled-components'

const TypographyStyled = styled.p`
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px !important`};
  line-height: ${({ lineHeight }) => lineHeight && `${lineHeight}px`};
  /* margin: ${({ margin }) => margin || '1em 0'}; */
  text-align: ${({ align }) => align};
  text-transform: ${({ transform }) => transform};
  font-family: ${({ fontFamily }) => fontFamily};
  text-overflow: ellipsis;
  overflow: ${({ overflow }) => (overflow || 'hidden')};
`
TypographyStyled.defaultProps = {

}

const H1Styled = styled(TypographyStyled)`
  font-size: 4em;
`
export function H1({ children, ...props }) {
  return (
    <H1Styled as="h1" {...props}>
      {children}
    </H1Styled>
  )
}

const H2Styled = styled(TypographyStyled)`
  font-size: 2em;
`
export function H2({ children, ...props }) {
  return (
    <H2Styled as="h2" {...props}>
      {children}
    </H2Styled>
  )
}

const H3Styled = styled(TypographyStyled)`
  font-size: 1.5em;
`
export function H3({ children, ...props }) {
  return (
    <H3Styled as="h3" {...props}>
      {children}
    </H3Styled>
  )
}

const H4Styled = styled(TypographyStyled)`
  font-size: 1.3125em;
`
export function H4({ children, ...props }) {
  return (
    <H4Styled as="h4" {...props}>
      {children}
    </H4Styled>
  )
}

const PStyled = styled(TypographyStyled)`
  font-size: 1em;
`

export function P({ children, ...props }) {
  return (
    <PStyled as="p" {...props}>
      {children}
    </PStyled>
  )
}


const DesignSystemStyled = styled(TypographyStyled)`
  text-align: ${({ align }) => align};
  font: ${({ small }) => (small ? `var(--${small})` : 'var(--p2Regular)')};
  font: ${({ font }) => (font ? `var(--${font})` : '')};
  @media(min-width: 768px) {
    font: ${({ large }) => `var(--${large})`};
  }
`

export function Typography(props) {
  const { children, font, large, small } = props
  const largeFont = large || (font || small || 'regular')
  return (
    <DesignSystemStyled {...props} large={largeFont}>
      {children}
    </DesignSystemStyled>
  )
}

export const PinkGradient = `
  color: var(--secondary);
  background-image: linear-gradient(258.75deg, #43B1C8 31.33%, #9989EB 48.42%, #D97FD1 59.35%, #F582B2 65.56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
`

export const DarkGradient = `
  color: var(--secondary);
  background-image: linear-gradient(258.75deg, #43B1C8 31.33%, #9989EB 48.42%, #D97FD1 59.35%, #F582B2 65.56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
`
