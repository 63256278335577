const BASE_API = process.env.API_HOST || 'https://api.learning.rest'

async function handlePromise(url = '', options = null, validateDeploy = false) {
  const response = await fetch(`${BASE_API}${url}`, options)
  if (!response.ok) {
    if (response.status === 400) {
      const error = await response.json()
      const cause = error[Object.keys(error)[0]][0]
      const firstError = cause.code
      throw new Error(`${firstError}`, { cause })
    }
    if (response.status === 401) throw new Error('User Unauthorized')
    if (response.status === 403) {
      const data = await response.json()
      throw new Error(data.code)
    }
    if (response.status === 404) throw new Error('Not Found')
    if (validateDeploy) {
      if (response.status === 503) throw new Error('Deploy')
      if (response.status === 502) throw new Error('Deploy')
    }
    throw new Error('Internal Error')
  }
  const data = await response.json()
  return data
}

export default handlePromise
